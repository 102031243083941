































































import { Component, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { PaymentsModule, Member } from "@/store/payments-module";
import { TeamsApi, Team } from "@/store/teams-api";
import CForm from "@/common/components/CForm.vue";
import CPayDialog from "@/common/components/CPayDialog.vue";
import SubMember from "./SubMember.vue";

@Component({ components: { CForm, CPayDialog, SubMember } })
export default class Subs extends Vue {
    paymentsStore = getModule(PaymentsModule);
    teamsStore = new TeamsApi();
    isMounted = false;
    teams: Team[] = [];
    payment = 0;
    members: Member[] = [{} as Member];

    mounted() {
        // get the teams
        this.teamsStore.getAll({ teamCategory: "" }).then((res) => (this.teams = res.data));
    }

    add() {
        this.members.push({} as Member);
    }

    remove(index: number) {
        this.members.splice(index, 1);
    }

    clear() {
        this.payment = 0;
    }

    pay() {
        if (!(this.$refs.form as any).validate()) {
            return;
        }

        this.paymentsStore
            .subs({ members: this.members, payment: this.payment })
            .then((res) => (this.$refs.payDialog as any).open(res.data.clientSecret));
    }
}
