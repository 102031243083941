






















































import { Component, Vue, Model, Prop, Emit } from "vue-property-decorator";
import { Member } from "@/store/payments-module";
import { Team } from "@/store/teams-api";
import CForm from "@/common/components/CForm.vue";
import { required, maxLength } from "@/common/validators/rules";

@Component({ components: { CForm } })
export default class SubMember extends Vue {
    @Model("change") member!: Member;
    @Prop() teams!: Team[];
    @Prop({ required: true, type: Boolean }) canRemove!: boolean;

    validators = { required, maxLength };
    firstName = "";
    lastName = "";
    team: string[] = [];
    period = 0;
    periods = [
        { value: "1", text: "Annual" },
        { value: "2", text: "Term 1" },
        { value: "3", text: "Term 2" },
    ];

    @Emit()
    remove() {
        return;
    }
}
